import React from 'react';
import SEO from './../components/seo';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './../styles/GlobalStyles';
import { theme } from '../Theme/theme';
import Navigation from './../components/Navigation/Navigation';
import Footer from './../components/Footer/Footer';

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <SEO title="projektowanie graficzne" />
      <GlobalStyle />
      <Navigation />
      {children}
      <Footer />
    </>
  </ThemeProvider>
);

export default Layout;
