import React from 'react';
import styled from 'styled-components';
import { Link, graphql, useStaticQuery } from 'gatsby';

const StyledLogo = styled.img`
  height: ${({ height }) => height};
`;

const Logo = ({ color, height }) => {
  const data = useStaticQuery(graphql`
    query {
      imageLogo: file(name: { eq: "pg_logo" }) {
        publicURL
      }
      imageLogoWhite: file(name: { eq: "pg_logo_white" }) {
        publicURL
      }
    }
  `);

  return (
    <StyledLogo
      src={
        color === 'white'
          ? data.imageLogoWhite.publicURL
          : data.imageLogo.publicURL
      }
      alt="logo"
      height={height}
    />
  );
};

const StyledLink = styled(Link)`
  .margin-0 {
    line-height: 0%;
    display: none;
    padding: 20px;
  }
`;

const StyledNavigation = styled.nav`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
`;

const Navigation = () => (
  <StyledNavigation>
    <Link to="/" className="lh-0">
      <Logo height="40" />
    </Link>
  </StyledNavigation>
);

export default Navigation;
