import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

const StyledWrapper = styled.div`
  width: 100%;
  padding: 20px 0;
  background-color: ${({ theme }) => theme.colors.secondaryBlue};
`;

const InnerWrapper = styled.div`
  width: ${({ theme }) => theme.media.desktop};
  color: white;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-weight: ${({ theme }) => theme.fonts.light};
  @media (max-width: ${({ theme }) => theme.media.desktop}) {
    width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    flex-direction: column;
  }
`;

const FooterMail = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  padding-right: 20px;
  border-right: 1px solid #ffffff;
  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    padding: 0;
    border-right: none;
    text-align: center;
    margin-bottom: 20px;
  }
`;
const FooterAddress = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    padding: 0;
    text-align: center;
  }
`;

const Logotypes = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  .pgLogo {
    width: 250px;
  }
  .imhoLogo {
    width: 80px;
  }
  .pixelLogo {
    width: 140px;
    margin-left: 20px;
  }
  span {
    display: inline-block;
    text-align: center;
    letter-spacing: 4px;
    margin: 4px 20px 0;
    @media (max-width: ${({ theme }) => theme.media.tablet}) {
      width: 100%;
      margin: 4px 0;
    }
  }
`;

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      pg_inline_logo: file(name: { eq: "pg_logo_inline_gray" }) {
        publicURL
      }
      imho_logo_gray: file(name: { eq: "imho_logo_gray" }) {
        publicURL
      }
      pixelform_logo_gray: file(name: { eq: "pixelform_logo_gray" }) {
        publicURL
      }
    }
  `);

  return (
    <StyledWrapper>
      <InnerWrapper>
        <FooterMail>
          <span>info@projektowaniegraficzne.org</span>
          <span>patryk@projektowaniegraficzne.org</span>
        </FooterMail>
        <FooterAddress>
          <span>ul. Morska 4b/4,6; 75-218 Koszalin</span>
          <span>tel. 737 46 45 40; 510 45 20 95</span>
        </FooterAddress>
        <Logotypes>
          <img
            src={data.pg_inline_logo.publicURL}
            className="pgLogo"
            alt="Logo projektowaniegraficzne.org"
          />
          <span>powered by</span>
          <img
            src={data.imho_logo_gray.publicURL}
            className="imhoLogo"
            alt="Logo imho"
          />
          <img
            src={data.pixelform_logo_gray.publicURL}
            className="pixelLogo"
            alt="Logo pixelform"
          />
        </Logotypes>
      </InnerWrapper>
    </StyledWrapper>
  );
};

export default Footer;
